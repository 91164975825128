
import { defineComponent } from "vue";
import YearMonth from "../../instances/YearMonth";
export default defineComponent({
  name: "DateFilter",
  props: {
    month: {
      default: false,
      type: Boolean,
    },
    year: {
      default: false,
      type: Boolean,
    },
    theme: {
      default: "white",
      type: String,
    },
  },
  data() {
    return {
      yearValue: "",
      monthValue: "",
    };
  },
  methods: {
    handleClick() {
      this.$store.dispatch("setDate", {
        year: this.yearValue,
        month: this.monthValue,
      });
      this.$emit("changeDate", {
        year: this.yearValue,
        month: this.monthValue,
      });
    },
  },
  created() {
    const date: YearMonth = this.$store.getters.geyDate;
    this.yearValue = date.year;
    this.monthValue = date.month;
  },
});
