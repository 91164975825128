<template>
  <div class="navBarDashboard shadow-sm">
    <div class="img-logo mx-auto d-none d-md-block"></div>
    <ul class="navbar-nav d-block d-md-none">
      <li class="text-end fs-1">
        <span class="text-white fs-3 position-absolute" style="left: 1em; top:1em">Sistema Apple v2</span>
        <i class="fas fa-bars border rounded p-2" @click="openMenu"></i>
      </li>
    </ul>
    <ul class="navbar-nav d-md-block" :class="menuIsOpen ? 'd-block' : 'd-none'">
      <li
        v-for="route in routes"
        :key="route.name"
        class="nav-item"
        :class="
          route.name == $route.name || route.name == $route.meta.parent
            ? 'active'
            : ''
        "
        @click="handleClickMenu(route.name, route.dropDown)"
      >
        <span v-if="!route.dropDown"
          ><i :class="route.ico"></i>
          <span>{{ route.title }}</span></span
        >
        <span v-if="route.dropDown" class="pl-1">
          <i :class="route.ico"></i> {{ route.title }}
          <i class="fas fa-caret-down" :class="isOpenDropDown(route.name) ? 'rotate-90' : '' "></i
        ></span>
        <div v-if="route.dropDown" v-show="isOpenDropDown(route.name)">
          <div
            class="nav-item"
            v-for="child in route.childs"
            :key="child.title"
            @click="handleClickMenu(child.name, child.dropDown)"
          >
           <i :class="child.ico"></i>
              <span>{{ child.title }}</span>
          </div>
        </div>
      </li>

      <li class="nav-item-line">
        <hr />
      </li>
      <li class="nav-item" @click="closeSesion">
        <i class="fas fa-sign-out-alt"></i><span>Cerrar sesión</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface subDropDown {
  title: string;
  ico: string;
  name: string;
}

interface Routes {
  name: string;
  ico: string;
  title: string;
  order: number;
  dropDown: boolean;
  childs: subDropDown[];
}

interface DropDownLi {
  name: string;
  show: boolean;
}

export default defineComponent({
  name: "NavBarDashboard",
  data() {
    return {
      routes: [] as Routes[],
      dropDown: [] as DropDownLi[],
      menuIsOpen: false,
    };
  },
  methods: {
    openMenu(){
      this.menuIsOpen = !this.menuIsOpen;
    },
    handleClickMenu(route: string, dropDown: boolean): void{
      if(dropDown)
        this.openDropDown(route)
      else
        this.$router.push({name: route})
    },
    closeSesion(): void{
      this.$store.dispatch("clearAccount");
      location.href = '/'
    },
    openDropDown(name: string): void {
      let li = this.dropDown.find((e: DropDownLi) => e.name == name);
      if (!li) return;
      li.show = !li.show;
    },
    isOpenDropDown(name: string): boolean {
      let li = this.dropDown.find((e: DropDownLi) => e.name == name);
      if (!li) return false;
      return li.show;
    },
  },
  created: function () {
    const routes: Routes[] = this.$router
      .getRoutes()
      .filter((element) => element.meta.adminRoot)
      .map((element) => {
        let childs: subDropDown[] = [];
        if (element.meta.dropDown) {
          this.dropDown.push({
            name: String(element.name),
            show: false,
          });
          childs = this.$router
            .getRoutes()
            .filter(
              // eslint-disable-next-line
              (child: any) =>
                child.meta.parent == element.name && child.meta.adminNav
            )
            // eslint-disable-next-line
            .map((child: any) => {
              return {
                ico: String(child.meta.ico),
                title: String(child.meta.title),
                name: String(child.name),
              };
            });
        }
        return {
          ico: String(element.meta.ico),
          title: String(element.meta.title),
          name: String(element.name),
          order: Number(element.meta.order),
          dropDown: Boolean(element.meta.dropDown),
          childs,
        };
      })
      .sort((a: Routes, b: Routes) => (a.order > b.order ? 1 : -1));
    this.routes = routes;
  },
});
</script>

<style scoped>
.navBarDashboard {
  background-color: #161619;
  user-select: none;
}

.img-logo {
  height: 100px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("~@/assets/images/logos/logo-blanco.png");
}
ul {
  color: #ffffff;
  font-weight: 600;
  padding: 1em;
  min-width: 225px;
}

.nav-item {
  margin: 0.2em 0;
  padding: 0.5em;
  cursor: pointer;
  border-left: #161619 3px solid;
}

.nav-item:hover {
  border-left: #414146 3px solid;
}

.nav-item.active {
  border-left: #33b18a 3px solid;
}

li > a > span {
  padding-left: 0.5em;
}

.nav-item-line > hr {
  color: #4a4a51;
}

a {
  text-decoration: none;
  color: white;
}

.rotate-90{
  transform: rotate(-90deg);
  animation-name: rotate-90;
  animation-duration: 0.3s;
}

@keyframes rotate-90 {
  from {transform: rotate(0)}
  to {transform: rotate(-90deg)}
}
</style>