<template>
  <div
    class="m-sm-4 m-2 p-sm-3 px-sm-5 px-2 shadow-sm rounded-5 border-light"
    style="background: white"
  >
    <p>
      <span class="btn btn-secondary" @click="$router.go(-1)"
        ><i class="fas fa-arrow-left"></i> Volver</span
      >
    </p>
    <p class="fs-3" v-show="onLoad"><SpanSkeleton width="20%" /></p>
    <p class="fs-3" v-show="!onLoad">Periodo: {{ periodo }}</p>
    <p class="fs-3" v-show="onLoad">
      <SpanSkeleton class="mx-auto" width="40%" />
    </p>
    <p class="fs-3 text-center" v-show="!onLoad">
      {{ title }}
      <StateBadge :state="estado" />
    </p>
    <TableSkeleton v-show="onLoad" />
    <table class="table table-hover" v-show="!onLoad">
      <thead>
        <tr>
          <th>Nivel</th>
          <th>Valor CRR</th>
          <th>Valor Final</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="nivel in niveles" :key="nivel.codigo">
          <td>{{ nivel.nivel }}</td>
          <td>{{ nivel.crr }}</td>
          <td>{{ nivel.total }}</td>
        </tr>
        <tr v-show="!niveles.length">
          <td colspan="3" class="text-center">no hay resultados</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TableSkeleton, SpanSkeleton } from "../../components/Skeleton";
import { StateBadge } from "../../components/Badge";
import BonoNivel from "../../instances/BonoNivel";

export default defineComponent({
  name: "BonosUserTable",
  components: {
    TableSkeleton,
    SpanSkeleton,
    StateBadge,
  },
  props: {
    niveles: {
      type: Array,
      default: (): BonoNivel[] => [],
    },
    onLoad: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    periodo: {
      type: String,
      default: "",
    },
    estado: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ESTADO_BONO: {},
    };
  },
});
</script>

<style scoped>
</style>