<template>
  <router-view class="router-view" />
</template>

<style>
  @import url('./assets/css/style.css');
  @import url('./assets/fontawesome-free-5.15.3-web/css/all.css');

.router-view {
  min-height: 100vh;
}
</style>
