<template>
  <div class="dashboard">
    <NavBarAdmin />
    <div class="content-wrapper p-3">
      <!-- <AwaitingRequests /> -->
      <div class="row m-0">
        <div class="col-lg-12 col-12 px-md-auto px-0">
          <CurrencyExchangeTable />
        </div>
        <!--
        <div class="col-lg-6 col-12 px-md-auto px-0">
          <CurrencyExchangeCalculator />
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Components
import { NavBarAdmin } from "../../components/Nav";
import { CurrencyExchangeTable } from "../../components/Table";
// import { CurrencyExchangeCalculator } from "../../components/Admin";

export default defineComponent({
  name: "AdminPanel",
  components: {
    NavBarAdmin,
    CurrencyExchangeTable,
    // CurrencyExchangeCalculator
    // AwaitingRequests
  },
});
</script>

<style scoped>
@media (max-width: 600px) {
  .dashboard {
    display: block !important;
  }
}

.dashboard {
  background-color: #f6f7fa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard .content-wrapper {
  width: 100%;
  padding-bottom: 80px;
}
</style>