import { createStore, Store } from 'vuex'
import { InjectionKey } from 'vue'
import Account from "../class/Account"
import DateFilter from "../class/DateFilter"
import YearMonth from '@/instances/YearMonth'
export interface State {
  account: Account,

}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore({
  state: {
    account: new Account(),
    date: new DateFilter()
  },
  mutations: {
    setAccount(state, account: Account){
      state.account = account;
      localStorage.setItem('jwt', account.jwt)
    },
    clearAccount(){
      localStorage.removeItem('jwt');
      location.href = '/';
    },
    setDate(state, date: YearMonth){
      state.date.setDate(date);
      state.date.month = Number(date.month) - 1;
      state.date.year = Number(date.year);
    }
  },
  actions: {
    setDate(context, date: YearMonth){
      context.commit("setDate", date)
    },
    setAccount(context, account: Account){
      context.commit("setAccount", account)
    },
    clearAccount(context){
      context.commit("clearAccount")
    }
  },
  getters: {
    geyDate(state){
      return {
        year: state.date.getYear(),
        month: state.date.getMonth()
      }
    },
    getAccountType(state){
      return state.account.accountType
    },
    getAccountJwt(state){
      return state.account.jwt
    },
  },
  modules: {
  }
})
