
import { defineComponent } from "vue";
import { TableSkeleton, SpanSkeleton } from "../../components/Skeleton";
import { StateBadge } from "../../components/Badge";
import BonoNivel from "../../instances/BonoNivel";

export default defineComponent({
  name: "BonosUserTable",
  components: {
    TableSkeleton,
    SpanSkeleton,
    StateBadge,
  },
  props: {
    niveles: {
      type: Array,
      default: (): BonoNivel[] => [],
    },
    onLoad: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    periodo: {
      type: String,
      default: "",
    },
    estado: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ESTADO_BONO: {},
    };
  },
});
