<template>
  <div
    class="modal fade"
    :class="show"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    :style="styleDisplay"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button
            @click="$emit('hideModal')"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-html="message">
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="$emit('hideModal')"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
        type: String,
        default: ""
    },
    message: {
        type: String,
        default: ""
    },
  },
  emits: ["hideModal"],
  data() {
    return {
      show: "" as string,
      styleDisplay: "display: none;" as string,
    }
  },
  watch: {
    display: function (newVal: string): void {
      if (newVal) {
        this.styleDisplay = "display: block;";
        setTimeout(() => {
          this.show = "show";
        }, 0.5);
      }else{
          this.show = "";
          this.styleDisplay = "display: hide;";
      }
    },
  },
});
</script>


<style>
</style>