<template>
  <div class="text-skeleton" :style="`background-color: ${color}; width: ${width}; height: ${height};`"></div>
</template>

<script>

import { defineComponent } from "vue";
export default defineComponent({
  name: "SpanSkeleton",
  props: {
    width: {
      type: String,
      default: "60%"
    },
    height: {
      type: String,
      default: "1em"
    },
    color: {
      type: String,
      default: "#c7c8cc"
    }
  },
});
</script>


<style scoped>
</style>