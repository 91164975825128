<template>
  <input
    type="text"
    class="form-control"
    :placeholder="`buscar por ${text}`"
    v-on:input="handleChange"
    :class="theme == 'dark' ? ' bg-dark text-white' : ''"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NameFilter",
  emits: ["handleChange"],
  props: {
    text: {
      type: String,
      required: false,
      default: "nombre",
    },
    theme: {
      default: "white",
      type: String,
    },
  },
  methods: {
    handleChange: function (e: any) {
      this.$emit("handleChange", e.target.value);
    },
  },
});
</script>

<style scoped>
</style>