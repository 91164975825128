<template>
    <div class="row">
      <div class="col th-skeleton">
        <SpanSkeleton />
      </div>
      <div class="col th-skeleton">
        <SpanSkeleton />
      </div>
      <div class="col th-skeleton">
        <SpanSkeleton />
      </div>
      <div class="col th-skeleton">
        <SpanSkeleton />
      </div>
    </div>
</template>

<script>
import SpanSkeleton from "./SpanSkeleton"
import { defineComponent } from "vue";
export default defineComponent({
    name: "ThSkeleton",
    components: {
      SpanSkeleton
    }
})
</script>

<style scoped>
.row {
  margin: 0.5em auto;
}
.th-skeleton {
  background-color: #eaeaea;
  padding: 1em 1em;
}
</style>