
import { defineComponent } from "vue";
import Api from "../../tools/api";
import Bono from "../../instances/Bono";
import YearMonth from "../../instances/YearMonth"
import { TIPO_BONO } from "../../configs/constant";
// Components
import { NavBarAdmin } from "../../components/Nav";
import { BonosTable } from "../../components/Table";
export default defineComponent({
  name: "BonosRetroactivos",
  components: {
    NavBarAdmin,
    BonosTable,
  },
  data() {
    return {
      table: {
        bonos: [] as Bono[],
        onLoad: true as boolean,
        title: "" as string,
      },
      typeBono: "",
    };
  },
  methods: {
        changeDate(){
      this.table.bonos = []
      this.table.onLoad = true;
      this.getBonos()
    },
    updateBono(bono: Bono) {
      this.table.bonos = this.table.bonos.map((e) => {
        if (bono.codigo == e.codigo) return { ...e, estado: bono.estado };
        return e;
      });
    },
    getBonos() {
      const date: YearMonth = this.$store.getters.geyDate;
    Api(`bonos?type=${this.typeBono}&year=${date.year}&month=${date.month}`)
      .get()
      .then((res) => {
        this.table = {
          bonos: res.data.bonos,
          onLoad: false,
          title: res.tipo,
        };
      })
      .catch(() => {
        console.log("alerts");
      });
  },
  },
    created() {
    this.typeBono = TIPO_BONO.RETROACTIVO;
    this.getBonos();
  },

});
