<template>
  <div>
    <div class="inputPushUp">
      <input
        class="form-control"
        @keyup.enter="$emit('keyupEnter', true)"
        :type="inputType"
        v-model="value"
        @change="$emit('change', value)"
        :disabled="disabled"
        :class="warn ? 'is-invalid' : ''"
        required
      />
      <span
        class="data-placeholder text-start"
        :data-placeholder="placeholder"
      ></span>
      <div class="invalid-feedback text-start" :class="warn ? '' : 'd-none'">
        {{ textWarn }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "InputPushUp",
  emits: ["keyupEnter", "change"],
  props: {
    inputType: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    setValue: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    warn: {
      type: Boolean,
      required: false,
      default: false,
    },
    textWarn: {
      type: String,
      required: false,
      default: "por favor, completa este campo",
    },
  },
  data: function () {
    return {
      value: "",
    };
  },
  created() {
    if (this.setValue) this.value = this.setValue;
  },
});
</script>

<style scoped>
.invalid-feedback {
  bottom: -20px;
  position: absolute;
}

.inputPushUp {
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 1rem !important;
  margin-top: 2.5rem !important;
}

input {
  font-family: Poppins-SemiBold;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 52px;
  background: transparent;
  padding: 0 5px;
  outline: none;
  border: none;
  overflow: visible;
}

input:valid + .data-placeholder::after {
  top: -20px;
  font-size: 15px;
}

input:valid + .data-placeholder::before {
  width: 100%;
}

input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

input:focus + .data-placeholder::after {
  top: -20px;
  font-size: 15px;
}

input:focus + .data-placeholder::before {
  width: 100%;
}

.data-placeholder {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.data-placeholder::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  background: #57b846;
}

.data-placeholder::after {
  font-family: Poppins-Medium;
  font-size: 18px;
  color: #999999;
  line-height: 1.2;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
</style>