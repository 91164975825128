

import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
        type: String,
        default: ""
    },
    message: {
        type: String,
        default: ""
    },
    btn: {
        type: String,
        default: "Aceptar"
    },
    emitValue: {
        type: String,
        default: ""
    },
    load: {
        type: Boolean,
        default: false
    },
    btnColor: {
      type: String,
      default: "btn-success"
    }
  },
  emits: ["handleClickBtn", "hideModal"],
  data() {
    return {
      show: "" as string,
      styleDisplay: "display: none;" as string,
    }
  },
  methods: {
      handleClickBtn(){
        this.$emit("handleClickBtn", this.emitValue);
      }
  },
  watch: {
    display: function (newVal: string): void {
      if (newVal) {
        this.styleDisplay = "display: block;";
        setTimeout(() => {
          this.show = "show";
        }, 0.5);
      }else{
          this.show = "";
          this.styleDisplay = "display: hide;";
      }
    },
  },
});
