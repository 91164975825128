<template>
  <div class="text-start">
    <ul class="px-0">
      <li v-show="recovery">
        <span>Olvidó su contraseña?</span>
        <router-link :to="{name: 'RecoveryPassword'}" class="text-link cursor-pointer"> Recuperar contraseña</router-link>
      </li>
      <li v-show="register">
        <span>Todavía no tienes una cuenta?</span>
        <router-link :to="{name: 'Register'}" class="text-link cursor-pointer"> Registrarse</router-link>
      </li>
      <li v-show="login">
        <span>Ya tienes una cuenta?</span>
        <router-link :to="{name: 'Login'}" class="text-link cursor-pointer"> Iniciar sesión</router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ListLogin",
  props: {
    recovery: {
      type: Boolean,
      default: true,
    },
    register: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
ul {
  list-style-type: none;
}
li {
  padding-left: 16px;
  padding-bottom: 0.2em;
  position: relative;
}

li::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #cccccc;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

.text-link {
  text-decoration: none;
  font-size: 15px;
  color: #57b846;
  line-height: 1.5;
}

.text-link:hover {
  text-decoration: none;
  color: #333333;
}
</style>