<template>
  <div>
    <div
      class="m-sm-4 m-2 p-3 px-sm-5 px-3 shadow-sm rounded-5 border-light bg-white"
    >
      <DateFilter :month="true" :year="true" v-on:changeDate="changeDate" />
      <div class="row">
        <div class="col-md-6 col-12 my-2">
          <NameFilter v-on:handleChange="handleChanceNameFilter" />
        </div>
        <div class="col-md-6 col-12 my-2">
          <StateFilter v-on:handleChange="handleChangeStateFilter" />
        </div>
      </div>
    </div>
    <div
      class="m-sm-4 m-2 p-3 px-sm-5 px-1 shadow-sm rounded-5 border-light bg-white"
    >
      <p class="fs-3 text-center">{{ title }}</p>
      <TableSkeleton v-show="onLoad" />
      <table class="table table-hover" v-show="!onLoad">
        <thead>
          <tr>
            <th>
              <span
                class="cursor-pointer"
                @click="handleChangeSorting('nombreUsuario')"
                >↓↑</span
              >
              Nombre
            </th>
            <th class="d-sm-table-cell d-none">
              <span class="cursor-pointer" @click="handleChangeSorting('valor')"
                >↓↑</span
              >
              Valor
            </th>

            <th class="text-center">Estado</th>
            <th class="d-sm-table-cell d-none" v-if="showCantidadUsuario">
              Cantidad de usuarios
            </th>
            <th class="d-sm-table-cell d-none" v-if="showSumatoria">
              Sumatoria de paquetes
            </th>
            <th class="text-center">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="bono in bonosFiltered"
            :key="bono.codigo"
            class="d-sm-table-row d-none"
          >
            <td class="text-break">{{ bono.nombreUsuario }}</td>
            <td>{{ bono.valor }}</td>
            <td class="text-center">
              <StateBadge :state="bono.estado" />
            </td>
            <th v-show="showCantidadUsuario">{{ bono.cant_usuarios }}</th>
            <th v-show="showSumatoria">{{ bono.sumatoria_paquetes }}</th>
            <td class="align-middle text-center">
              <Button
                v-if="bono.estado == ESTADO_BONO.PENDIENTE"
                bgColor="green"
                text="Pagar"
                class="mx-1 my-1"
                icon="fas fa-handshake"
                @click="handlePayBono(bono)"
              />
              <Button
                v-if="bono.estado == ESTADO_BONO.PENDIENTE"
                bgColor="red"
                text="Cancelar"
                class="mx-1 my-1"
                icon="fas fa-user-slash"
                @click="handleCancelBono(bono)"
              />
              <Button
                @click="
                  $router.push({
                    name: 'DetalleBono',
                    params: { id: bono.codigo, typeBono: typeBono.toLocaleLowerCase() },
                  })
                "
                text="Ver niveles"
                class="mx-1 my-1"
                icon="fas fa-bars"
                bgColor="blue"
                v-if="showVerNiveles"
              />
            </td>
          </tr>
          <tr
            v-for="bono in bonosFiltered"
            :key="'m-' + bono.codigo"
            class="d-sm-none d-table-row"
          >
            <td class="align-middle text-center">
              {{ bono.nombreUsuario }} <br />
              ${{ bono.valor }}
            </td>
            <td class="align-middle px-0 text-center">
              <StateBadge :state="bono.estado" />
            </td>
            <td class="text-center px-0 align-middle">
              <Button
                class="mx-1"
                v-if="bono.estado == ESTADO_BONO.PENDIENTE"
                bgColor="green"
                icon="fas fa-handshake"
                @click="handlePayBono(bono)"
              />
              <Button
                class="mx-1"
                v-if="bono.estado == ESTADO_BONO.PENDIENTE"
                bgColor="red"
                icon="fas fa-user-slash"
                @click="handleCancelBono(bono)"
              />
              <Button
              v-if="showVerNiveles"
                icon="fas fa-bars"
                class="mx-1 mt-1"
                bgColor="blue"
                @click="
                  $router.push({
                    name: 'DetalleBono',
                    params: { id: bono.codigo, typeBono: typeBono.toLocaleLowerCase() },
                  })
                "
              />
            </td>
          </tr>
          <tr v-show="!bonosFiltered.length">
            <td
              :colspan="4 + Number(showCantidadUsuario) + Number(showSumatoria)"
              class="d-sm-table-cell d-none text-center"
            >
              no hay resultados
            </td>
            <td class="d-sm-none d-table-cell text-center" colspan="3">
              no hay resultados
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal
      :display="modalPayBono.display"
      v-on:hideModal="handleHideModalPayBono"
      :title="`Pagar ${title}`"
      :message="modalPayBono.message"
      btn="Pagar"
      v-on:handleClickBtn="handleClickBtnPayModal"
      :emitValue="modalPayBono.emitValue"
      :load="modalPayBono.load"
    />
    <Modal
      :display="modalCancelBono.display"
      v-on:hideModal="handleHideModalCancelBono"
      :title="`Cancelar ${title}`"
      :message="modalCancelBono.message"
      btn="Cancelar"
      v-on:handleClickBtn="handleClickBtnCancelModal"
      :emitValue="modalCancelBono.emitValue"
      :load="modalCancelBono.load"
      btnColor="btn-danger"
    />
    <ModalMessage
      :display="modalSuccess.display"
      title="Pagar bono de nivel"
      :message="modalSuccess.message"
      v-on:hideModal="handleHideModalSuccess"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Api from "../../tools/api";
import { filterByName, filterByState } from "../../tools/Filter";
import { NameFilter, DateFilter, StateFilter } from "../../components/Filter";
import { Modal, ModalMessage } from "../../components/Modal";
import { Button } from "../../components/Button";
import { TableSkeleton } from "../../components/Skeleton";
import { StateBadge } from "../../components/Badge";
import { ESTADO_BONO } from "../../configs/constant";
import Bono from "../../instances/Bono";
import YearMonth from "../../instances/YearMonth";

export default defineComponent({
  name: "BonosTable",
  components: {
    NameFilter,
    DateFilter,
    Button,
    TableSkeleton,
    StateBadge,
    StateFilter,
    Modal,
    ModalMessage,
  },
  props: {
    typeBono: {
      type: String,
      default: "typeBono",
    },
    bonos: {
      type: Array,
      default: (): Bono[] => [],
    },
    onLoad: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    showSumatoria: {
      type: Boolean,
      default: false,
    },
    showCantidadUsuario: {
      type: Boolean,
      default: false,
    },
    showVerNiveles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filter: {
        name: "" as string,
        sortField: "codigo",
        sort: 1,
        state: "" as string,
      },
      ESTADO_BONO: {},
      modalPayBono: {
        display: false,
        message: "",
        emitValue: "",
        load: false,
      },
      modalSuccess: {
        display: false,
        message: "",
        load: false,
      },
      modalCancelBono: {
        display: false,
        message: "",
        emitValue: "",
        load: false,
      },
    };
  },
  methods: {
    changeDate(e:YearMonth){
      this.$emit('changeDate', e)
    },
    handleHideModalSuccess() {
      this.modalSuccess.display = false;
    },
    // Cancel bono
    handleClickBtnCancelModal(id: string) {
      this.modalCancelBono.load = true;
      Api("bonos")
        .post({
          tipo: this.typeBono,
          codigo: id,
          estado: ESTADO_BONO.CANCELADO,
        })
        .then((res) => {
          this.modalCancelBono.load = false;
          this.modalCancelBono.display = false;
          this.modalSuccess.message = res.info.user_msg;
          this.modalSuccess.display = true;
          this.$emit("updateBono", {
            codigo: id,
            estado: ESTADO_BONO.CANCELADO,
          });
        })
        .catch((err) => {
          console.log(err);
          this.modalCancelBono.load = false;
        });
    },
    handleHideModalCancelBono() {
      this.modalCancelBono.display = false;
    },
    handleCancelBono(bono: Bono) {
      this.modalCancelBono.emitValue = String(bono.codigo);
      this.modalCancelBono.message = `
        <p>¿estás seguro que desea cancelar el bono de <b>${bono.nombreUsuario}</b>?</p>
        <p>Valor a pagar: <b>${bono.valor}</b></p>
        `;
      this.modalCancelBono.display = !this.modalCancelBono.display;
    },
    // Pay Bono
    handleClickBtnPayModal(id: string) {
      this.modalPayBono.load = true;
      Api("bonos")
        .post({
          tipo: this.typeBono,
          codigo: id,
          estado: ESTADO_BONO.PAGADO,
        })
        .then((res) => {
          this.modalPayBono.load = false;
          this.modalPayBono.display = false;
          this.modalSuccess.message = res.info.user_msg;
          this.modalSuccess.display = true;
          this.$emit("updateBono", { codigo: id, estado: ESTADO_BONO.PAGADO });
        })
        .catch((err) => {
          console.log(err);
          this.modalPayBono.load = false;
        });
    },
    handleHideModalPayBono() {
      this.modalPayBono.display = false;
    },
    handlePayBono(bono: Bono) {
      this.modalPayBono.emitValue = String(bono.codigo);
      this.modalPayBono.message = `
        <p>¿estás seguro que desea pagar el bono de <b>${bono.nombreUsuario}</b>?</p>
        <p>Valor a pagar: <b>${bono.valor}</b></p>
        `;
      this.modalPayBono.display = !this.modalPayBono.display;
    },
    handleChanceNameFilter($event: string) {
      this.filter.name = $event.toUpperCase();
    },
    handleChangeSorting(filed: string) {
      this.filter.sortField = filed;
      this.filter.sort *= -1;
    },
    handleChangeStateFilter($event: string) {
      this.filter.state = $event;
    },
  },
  created() {
    this.ESTADO_BONO = ESTADO_BONO;
  },
  computed: {
    bonosFiltered(): Bono[] {
      const filteredByState = filterByState(this.bonos, this.filter.state);
      const filteredByName = filterByName(
        filteredByState,
        this.filter.name
      ).sort((a: Bono, b: Bono) => {
        if (a[this.filter.sortField] > b[this.filter.sortField])
          return this.filter.sort * -1;
        else return this.filter.sort;
      });
      return filteredByName;
    },
  },
});
</script>

<style scoped>
table {
  table-layout: fixed;
  word-break: break-all;
}
</style>