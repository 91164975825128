export const filterByName = (arr: any[], str: string) =>
    arr.filter((a: any) => {
        let ret = false;
        Object.keys(a).forEach((f: string) => {
            if (String(a[f]).toUpperCase().includes(str)) ret = true;
        });
        return ret;
    })

export const filterByState = (arr: any[], str: string, objetKey = "estado") => {
    if(str == "0" || !str)
        return arr;
    
    return arr.filter((a: any) => a[objetKey] == str)
}
 