export default class Account {
    accountType: string
    jwt: string
  
    constructor(jwt: string | null = "", accountType = "") {
      if(jwt){
        this.jwt = jwt
        this.accountType = accountType
        return
      }

      const localJwt = localStorage.getItem('jwt');
      this.jwt = localJwt ? localJwt : ''
      this.accountType = ""
    }
  }