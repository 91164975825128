
import { defineComponent } from "vue";
export default defineComponent({
  name: "InputPushUp",
  emits: ["keyupEnter", "change"],
  props: {
    inputType: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    setValue: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    warn: {
      type: Boolean,
      required: false,
      default: false,
    },
    textWarn: {
      type: String,
      required: false,
      default: "por favor, completa este campo",
    },
  },
  data: function () {
    return {
      value: "",
    };
  },
  created() {
    if (this.setValue) this.value = this.setValue;
  },
});
