<template>
  <div :class="block ? 'd-grid gap-2' : ''">
    <span v-show="!loading" class="btn cursor-pointer button-large">{{
      text
    }}</span>
    <span v-show="loading" class="btn cursor-pointer button-large text-capitalize"
      ><div class="spinner-border spinner-border-sm mr-1" role="status"></div>
      Cargando...</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ButtonLarge",
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.button-large {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  align-items: center;
  padding: 10px 25px;
  background-color: #57b846;
  border-radius: 25px;
  box-shadow: 0 10px 30px 0px rgb(87 184 70 / 50%);
  -moz-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgb(87 184 70 / 50%);
  -o-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.button-large:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgb(51 51 51 / 50%);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgb(51 51 51 / 50%);
}
</style>