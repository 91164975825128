
import { defineComponent } from "vue";
export default defineComponent({
  name: "ButtonLarge",
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
});
