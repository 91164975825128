
import { defineComponent } from "vue";
import Api from "../../tools/api";
import { filterByName, filterByState } from "../../tools/Filter";
import { NameFilter, DateFilter, StateFilter } from "../../components/Filter";
import { Modal, ModalMessage } from "../../components/Modal";
import { Button } from "../../components/Button";
import { TableSkeleton } from "../../components/Skeleton";
import { StateBadge } from "../../components/Badge";
import { ESTADO_BONO } from "../../configs/constant";
import Bono from "../../instances/Bono";
import YearMonth from "../../instances/YearMonth";

export default defineComponent({
  name: "BonosTable",
  components: {
    NameFilter,
    DateFilter,
    Button,
    TableSkeleton,
    StateBadge,
    StateFilter,
    Modal,
    ModalMessage,
  },
  props: {
    typeBono: {
      type: String,
      default: "typeBono",
    },
    bonos: {
      type: Array,
      default: (): Bono[] => [],
    },
    onLoad: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    showSumatoria: {
      type: Boolean,
      default: false,
    },
    showCantidadUsuario: {
      type: Boolean,
      default: false,
    },
    showVerNiveles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filter: {
        name: "" as string,
        sortField: "codigo",
        sort: 1,
        state: "" as string,
      },
      ESTADO_BONO: {},
      modalPayBono: {
        display: false,
        message: "",
        emitValue: "",
        load: false,
      },
      modalSuccess: {
        display: false,
        message: "",
        load: false,
      },
      modalCancelBono: {
        display: false,
        message: "",
        emitValue: "",
        load: false,
      },
    };
  },
  methods: {
    changeDate(e:YearMonth){
      this.$emit('changeDate', e)
    },
    handleHideModalSuccess() {
      this.modalSuccess.display = false;
    },
    // Cancel bono
    handleClickBtnCancelModal(id: string) {
      this.modalCancelBono.load = true;
      Api("bonos")
        .post({
          tipo: this.typeBono,
          codigo: id,
          estado: ESTADO_BONO.CANCELADO,
        })
        .then((res) => {
          this.modalCancelBono.load = false;
          this.modalCancelBono.display = false;
          this.modalSuccess.message = res.info.user_msg;
          this.modalSuccess.display = true;
          this.$emit("updateBono", {
            codigo: id,
            estado: ESTADO_BONO.CANCELADO,
          });
        })
        .catch((err) => {
          console.log(err);
          this.modalCancelBono.load = false;
        });
    },
    handleHideModalCancelBono() {
      this.modalCancelBono.display = false;
    },
    handleCancelBono(bono: Bono) {
      this.modalCancelBono.emitValue = String(bono.codigo);
      this.modalCancelBono.message = `
        <p>¿estás seguro que desea cancelar el bono de <b>${bono.nombreUsuario}</b>?</p>
        <p>Valor a pagar: <b>${bono.valor}</b></p>
        `;
      this.modalCancelBono.display = !this.modalCancelBono.display;
    },
    // Pay Bono
    handleClickBtnPayModal(id: string) {
      this.modalPayBono.load = true;
      Api("bonos")
        .post({
          tipo: this.typeBono,
          codigo: id,
          estado: ESTADO_BONO.PAGADO,
        })
        .then((res) => {
          this.modalPayBono.load = false;
          this.modalPayBono.display = false;
          this.modalSuccess.message = res.info.user_msg;
          this.modalSuccess.display = true;
          this.$emit("updateBono", { codigo: id, estado: ESTADO_BONO.PAGADO });
        })
        .catch((err) => {
          console.log(err);
          this.modalPayBono.load = false;
        });
    },
    handleHideModalPayBono() {
      this.modalPayBono.display = false;
    },
    handlePayBono(bono: Bono) {
      this.modalPayBono.emitValue = String(bono.codigo);
      this.modalPayBono.message = `
        <p>¿estás seguro que desea pagar el bono de <b>${bono.nombreUsuario}</b>?</p>
        <p>Valor a pagar: <b>${bono.valor}</b></p>
        `;
      this.modalPayBono.display = !this.modalPayBono.display;
    },
    handleChanceNameFilter($event: string) {
      this.filter.name = $event.toUpperCase();
    },
    handleChangeSorting(filed: string) {
      this.filter.sortField = filed;
      this.filter.sort *= -1;
    },
    handleChangeStateFilter($event: string) {
      this.filter.state = $event;
    },
  },
  created() {
    this.ESTADO_BONO = ESTADO_BONO;
  },
  computed: {
    bonosFiltered(): Bono[] {
      const filteredByState = filterByState(this.bonos, this.filter.state);
      const filteredByName = filterByName(
        filteredByState,
        this.filter.name
      ).sort((a: Bono, b: Bono) => {
        if (a[this.filter.sortField] > b[this.filter.sortField])
          return this.filter.sort * -1;
        else return this.filter.sort;
      });
      return filteredByName;
    },
  },
});
