<template>
    <div class="row">
      <div class="col tr-skeleton">
        <SpanSkeleton color="#e9edf0" />
      </div>
      <div class="col tr-skeleton">
        <SpanSkeleton color="#e9edf0" />
      </div>
      <div class="col tr-skeleton">
        <SpanSkeleton color="#e9edf0" />
      </div>
      <div class="col tr-skeleton">
        <SpanSkeleton color="#e9edf0" />
      </div>
    </div>
</template>

<script>
import SpanSkeleton from "./SpanSkeleton"
import { defineComponent } from "vue";
export default defineComponent({
    name: "TrSkeleton",
    components: {
      SpanSkeleton
    }
})
</script>

<style scoped>
.row {
  margin: 0.5em auto;
}
.tr-skeleton {
  background-color: #fbfbfb;
  padding: 1em 1em;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: pulse;
}
.tr-skeleton > .text-skeleton {
  background-color: #e9edf0;
  width: 60%;
  height: 1em;
}

@keyframes pulse {
  0% {
    background-color: #fbfbfb;
  }
  50% {
    background-color: #F7F7F7;
  }
  100% {
    background-color: #fbfbfb;
  }
}
</style>