
import { defineComponent } from "vue";

import { TableSkeleton } from "../../components/Skeleton";
export default defineComponent({
  name: "CurrencyExchangeTable",
  components: {
        TableSkeleton
  },
  data(){
    return {
      onLoad: true,
      exchangeTable: {
      }
    }
  },
  created(){
    fetch('https://www.sistemaapple.com.mx/api/getTiposCambio', {
      method: 'GET'
    }).then( res => res.json())
    .then(res => {
      this.onLoad = false;
      this.exchangeTable = res
    })
  }
});
