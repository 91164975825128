
import { defineComponent } from "vue";
export default defineComponent({
  name: "ListLogin",
  props: {
    recovery: {
      type: Boolean,
      default: true,
    },
    register: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Boolean,
      default: false,
    },
  },
});
