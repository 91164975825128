<template>
  <span class="badge text-uppercase" :class="style">{{ title }}</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ESTADO_BONO } from "../../configs/constant";

export default defineComponent({
  name: "StateBadge",
  props: {
    state: {
      type: String,
      required: true,
    },
    short: {
        type: Boolean,
        default: false
      }
  },
  computed: {
    title(): string {
      return (this.short ? this.state.slice(0,3) : this.state).toLocaleLowerCase();
    },
    style(): string {
      switch (this.state) {
        case ESTADO_BONO.PENDIENTE:
          return "badge bg-warning text-dark";
        case ESTADO_BONO.PAGADO:
          return "badge bg-success";
        case ESTADO_BONO.CANCELADO:
          return "badge bg-danger";
        default:
          return "badge bg-primary";
      }
    },
  },
});
</script>