import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ORDER_ROUTE } from '../configs/constant'

import Home from '../views/Home.vue'

// Login
import Login from '../views/Login.vue'
import RecoveryPassword from "../views/RecoveryPassword.vue"
import Register from "../views/Register.vue"

// Admin
import AdminPanel from "../views/admin/AdminPanel.vue"
import BonosAppleGolden from "../views/admin/BonosAppleGolden.vue"
import BonosEstrella from "../views/admin/BonosEstrella.vue"
import BonosPatrocinador from "../views/admin/BonosPatrocinador.vue"
import BonosPorBeneficios from "../views/admin/BonosPorBeneficios.vue"
import BonosPorNivel from "../views/admin/BonosPorNivel.vue"
import DetalleBono from "../views/admin/DetalleBono.vue"
import BonosRendimientosRA from "../views/admin/BonosRendimientosRA.vue"
import BonosRetroactivos from "../views/admin/BonosRetroactivos.vue"

const bondsRoutes: Array<RouteRecordRaw> = [
  {
    path: '/admin/bonos-por-nivel',
    name: 'BonosPorNivel',
    component: BonosPorNivel,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos por nivel',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bonos-de-beneficios',
    name: 'BonosPorBeneficios',
    component: BonosPorBeneficios,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos por beneficios',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bonos-estrella',
    name: 'BonosEstrella',
    component: BonosEstrella,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos estrella',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bonos-retroactivos',
    name: 'BonosRetroactivos',
    component: BonosRetroactivos,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos retroactivos',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bonos-patrocinador',
    name: 'BonosPatrocinador',
    component: BonosPatrocinador,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos patrocinador',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bonos-rendimientos-RA',
    name: 'BonosRendimientosRA',
    component: BonosRendimientosRA,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos rendimientos RA',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bonos-apple-golden',
    name: 'BonosAppleGolden',
    component: BonosAppleGolden,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'Bonos apple golden',
      parent: 'Bonds'
    }
  }, /*{
    path: '/admin/registro-liderazgo',
    name: 'RegistroLiderazgo',
    component: BonosPorNivel,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'XRegistro liderazgo',
      parent: 'Bonds'
    }
  }, {
    path: '/admin/bono-liderazgo',
    name: 'BonoLiderazgo',
    component: BonosPorNivel,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: true,
      title: 'XBono liderazgo',
      parent: 'Bonds'
    }
  },*/ {
    path: '/admin/:typeBono/:id',
    name: 'DetalleBono',
    component: DetalleBono,
    meta: {
      admin: true,
      adminRoot: false,
      adminNav: false,
      parent: 'Bonds',
    }
  }, {
    path: "/admin/bonos",
    name: "Bonds",
    component: BonosPorNivel,
    meta: {
      admin: true,
      adminRoot: true,
      dropDown: true,
      title: 'Bonos',
      ico: 'fas fa-sliders-h',
      order: ORDER_ROUTE.BONDS
    }
  }
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/recuperar',
    name: 'RecoveryPassword',
    component: RecoveryPassword
  }, {
    path: '/registro',
    name: 'Register',
    component: Register
  },
  {
    path: '/admin',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      admin: true,
      adminRoot: true,
      ico: 'fas fa-home',
      title: 'Inicio',
      order: 0
    }
  },
  ...bondsRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
