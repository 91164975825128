<template>
  <div class="navBarDashboard">
    <div class="img-logo mx-auto"></div>
    <ul class="navbar-nav">
      <li class="nav-item active">
        <i class="fas fa-home"></i> <span>Inicio</span>
      </li>
      <li class="nav-item">
        <i class="fas fa-sliders-h"></i><span>Mis datos</span>
      </li>
      <li class="nav-item"><i class="fas fa-tree"></i><span>Tu arbol</span></li>
      <li class="nav-item">
        <i class="fas fa-project-diagram"></i><span>Representantes en Red</span>
      </li>
      <li class="nav-item-line">
        <hr>
      </li>
            <li class="nav-item">
        <i class="fas fa-sign-out-alt"></i><span>Cerrar sesión</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NavBarDashboard",
});
</script>

<style scoped>
.navBarDashboard {
  background-color: #161619;
}

.img-logo {
  height: 100px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("~@/assets/images/logos/logo-blanco.png");
}
ul {
  color: #ffffff;
  font-weight: 600;
  padding: 1em;
  min-width: 225px;
}

.nav-item {
  margin: 0.2em 0;
  padding: 0.5em;
  cursor: pointer;
  border-left: #161619 3px solid;
}

.nav-item:hover {
  border-left: #414146 3px solid;
}

.nav-item.active {
  border-left: #33b18a 3px solid;
 /* background-color: #33b18a; 
  border-radius: 0.5em;*/
}

li > span {
  padding-left: 0.5em;
}

.nav-item-line>hr{
  color: #4A4A51;
}
</style>