
import { defineComponent } from "vue";
export default defineComponent({
  name: "NameFilter",
  emits: ["handleChange"],
  props: {
    text: {
      type: String,
      required: false,
      default: "nombre",
    },
    theme: {
      default: "white",
      type: String,
    },
  },
  methods: {
    handleChange: function (e: any) {
      this.$emit("handleChange", e.target.value);
    },
  },
});
