<template>
  <div class="d-inline-block" :class="block ? 'd-grid gap-2' : ''">
    <span v-show="!loading" class="btn cursor-pointer" :class="dBgColor"><i v-if="icon" :class="icon"></i> {{
      text 
    }}</span>
    <span v-show="loading" class="btn cursor-pointer btn-dark text-capitalize"
      ><div class="spinner-border spinner-border-sm mr-1" role="status"></div>
      Cargando...</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Button",
  props: {
    text: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "black",
    },
    icon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dBgColor: "btn-dark" as string,
    };
  },
  created() {
    switch (this.bgColor) {
      case "black":
        this.dBgColor = "btn-dark text-white";
        break;
      case "green":
        this.dBgColor = "btn-success";
        break;
      case "yellow":
        this.dBgColor = "btn-warning";
        break;
      case "blue":
         this.dBgColor = "btn-blue text-white ";
        break;
      case "red":
        this.dBgColor = "btn-danger"
        break;
    }
  },
});
</script>

<style scoped>
</style>