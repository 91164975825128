<template>
  <div>
    <select
      class="form-select"
      v-on:change="handleChange($event)"
    >
      <option selected value="0">Todos los estados</option>
      <option v-for="state in states" :key="state" :value="state">{{state.toLocaleLowerCase()}}</option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ESTADO_BONO } from "../../configs/constant"

export default defineComponent({
  name: "StateFilter",
  data(){
      return {
          states: {}
      }
  },
  created(){
      this.states = ESTADO_BONO;
  },  
  methods: {
    handleChange: function (e: any) {
      this.$emit("handleChange", e.target.value);
    },
  },
});
</script>


<style lang="css">
</style>