
import { defineComponent } from "vue";

interface subDropDown {
  title: string;
  ico: string;
  name: string;
}

interface Routes {
  name: string;
  ico: string;
  title: string;
  order: number;
  dropDown: boolean;
  childs: subDropDown[];
}

interface DropDownLi {
  name: string;
  show: boolean;
}

export default defineComponent({
  name: "NavBarDashboard",
  data() {
    return {
      routes: [] as Routes[],
      dropDown: [] as DropDownLi[],
      menuIsOpen: false,
    };
  },
  methods: {
    openMenu(){
      this.menuIsOpen = !this.menuIsOpen;
    },
    handleClickMenu(route: string, dropDown: boolean): void{
      if(dropDown)
        this.openDropDown(route)
      else
        this.$router.push({name: route})
    },
    closeSesion(): void{
      this.$store.dispatch("clearAccount");
      location.href = '/'
    },
    openDropDown(name: string): void {
      let li = this.dropDown.find((e: DropDownLi) => e.name == name);
      if (!li) return;
      li.show = !li.show;
    },
    isOpenDropDown(name: string): boolean {
      let li = this.dropDown.find((e: DropDownLi) => e.name == name);
      if (!li) return false;
      return li.show;
    },
  },
  created: function () {
    const routes: Routes[] = this.$router
      .getRoutes()
      .filter((element) => element.meta.adminRoot)
      .map((element) => {
        let childs: subDropDown[] = [];
        if (element.meta.dropDown) {
          this.dropDown.push({
            name: String(element.name),
            show: false,
          });
          childs = this.$router
            .getRoutes()
            .filter(
              // eslint-disable-next-line
              (child: any) =>
                child.meta.parent == element.name && child.meta.adminNav
            )
            // eslint-disable-next-line
            .map((child: any) => {
              return {
                ico: String(child.meta.ico),
                title: String(child.meta.title),
                name: String(child.name),
              };
            });
        }
        return {
          ico: String(element.meta.ico),
          title: String(element.meta.title),
          name: String(element.name),
          order: Number(element.meta.order),
          dropDown: Boolean(element.meta.dropDown),
          childs,
        };
      })
      .sort((a: Routes, b: Routes) => (a.order > b.order ? 1 : -1));
    this.routes = routes;
  },
});
