<template>
  <div class="dashboard">
    <NavBarAdmin />
    <div class="content-wrapper">
      <BonosUserTable
        :niveles="table.niveles"
        :onLoad="table.onLoad"
        :title="table.title"
        :periodo="table.periodo"
        :estado="table.estado"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BonoNivel from "../../instances/BonoNivel";
import Api from "../../tools/api";

// Components
import { NavBarAdmin } from "../../components/Nav";
import { BonosUserTable } from "../../components/Table";
export default defineComponent({
  name: "DetalleBono",
  components: {
    NavBarAdmin,
    BonosUserTable,
  },
  data() {
    return {
      table: {
        niveles: [] as BonoNivel[],
        onLoad: true as boolean,
        title: "" as string,
        periodo: "" as string,
        estado: "" as string,
      },
    };
  },
  created() {
    Api(`bonos?id=${this.$route.params.id}&tipo=${this.$route.params.typeBono}`)
      .get()
      .then((res) => {
        this.table = {
          niveles: res.data.niveles,
          onLoad: false,
          title: `[${res.data.bono.nombreUsuario}] Tipo de bono: ${res.data.bono.tipo}.`,
          periodo: res.data.bono.periodo,
          estado: res.data.bono.estado,
        };
      }).catch(err => {
        this.table.onLoad = false;
        console.log(err)
      });
  },
});
</script>

<style scoped>
@media (max-width: 600px) {
  .dashboard {
    display: block !important;
  }
}

.dashboard {
  background-color: #f6f7fa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard .content-wrapper {
  width: 100%;
  padding-bottom: 80px;
}
</style>