export const ESTADO_BONO = {
    PENDIENTE: 'PENDIENTE',
    PAGADO: 'PAGADO',
    CANCELADO: 'CANCELADO'
}

export const TIPO_BONO = {
    NIVEL: 'NIVEL',
    BENEFICIOS: 'BENEFICIOS',
    COMPRASAG: 'COMPRASAG',
    ESTRELLA: 'ESTRELLA',
    RETROACTIVO: 'RETROACTIVO',
    PATROCINADOR: 'PATROCINADOR',
    RENDIMIENTOSRA: 'RENDIMIENTOSRA'
}

export const ORDER_ROUTE = {
    BONDS: 1
}