<template>
  <div class="row">
    <div v-if="month" class="col-md-4 col-12 my-2">
      <select
        class="form-select"
        :class="theme == 'dark' ? ' bg-dark text-white' : ''"
        v-model="monthValue"
      >
        <option value="01">Enero</option>
        <option value="02">Febrero</option>
        <option value="03">Marzo</option>
        <option value="04">Abril</option>
        <option value="05">Mayo</option>
        <option value="06">Junio</option>
        <option value="07">Julio</option>
        <option value="08">Agosto</option>
        <option value="09">Septiembre</option>
        <option value="10">Octubre</option>
        <option value="11">Noviembre</option>
        <option value="12">Diciembre</option>
      </select>
    </div>
    <div v-if="year" class="col-md-4 col-12 my-2">
      <select
        class="form-select"
        :class="theme == 'dark' ? 'bg-dark text-white' : ''"
        v-model="yearValue"
      >
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
      </select>
    </div>
    <div class="col-md-4 col-12 mt-auto my-2">
      <span @click="handleClick" class="btn btn-dark w-100">Buscar</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import YearMonth from "../../instances/YearMonth";
export default defineComponent({
  name: "DateFilter",
  props: {
    month: {
      default: false,
      type: Boolean,
    },
    year: {
      default: false,
      type: Boolean,
    },
    theme: {
      default: "white",
      type: String,
    },
  },
  data() {
    return {
      yearValue: "",
      monthValue: "",
    };
  },
  methods: {
    handleClick() {
      this.$store.dispatch("setDate", {
        year: this.yearValue,
        month: this.monthValue,
      });
      this.$emit("changeDate", {
        year: this.yearValue,
        month: this.monthValue,
      });
    },
  },
  created() {
    const date: YearMonth = this.$store.getters.geyDate;
    this.yearValue = date.year;
    this.monthValue = date.month;
  },
});
</script>

<style scoped>
</style>