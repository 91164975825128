<template>
  <div class="home d-flex align-items-center h-100 px-3">
    <div class="home-container text-center mx-auto">
      <div class="img-logo mx-auto"></div>
      <InputPushUp
        placeholder="usuario"
        v-on:change="handleUser($event)"
        :warn="isEmptyUser"
        textWarn="ingrese un usuario"
      />
      <InputPushUp
        inputType="password"
        placeholder="contraseña"
        v-on:change="handlePdw($event)"
        :warn="isEmptyPwd"
        textWarn="ingrese una contraseña"
        v-on:keyup.enter="handleLogin()"
      />
      <ButtonLarge
        text="Iniciar Sesión"
        class="mb-3 mt-5"
        :block="true"
        :loading="isLogin"
        v-on:click="handleLogin()"
      />
      <p class="text-danger">{{ messageError }}</p>
      <ListLogin :recovery="true" :register="true" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Api from "../tools/api";

// Components
import { ButtonLarge } from "../components/Button";
import { InputPushUp } from "../components/Form";
import { ListLogin } from "../components/List";

// Classes
import Account from "../class/Account";

export default defineComponent({
  name: "Login",
  components: {
    ButtonLarge,
    InputPushUp,
    ListLogin,
  },
  data() {
    return {
      inputUser: "",
      inputPwd: "",
      isEmptyUser: false,
      isEmptyPwd: false,
      isLogin: false,
      messageError: "",
      sitekey: "6LcM7bkUAAAAAKppn1y0PVuMhK8VnFZEY0Ke4Tue",
    };
  },
  methods: {
    // v-on
    handleUser(user: string) {
      this.inputUser = user;
    },
    handlePdw(pwd: string) {
      this.inputPwd = pwd;
    },
    // onClick
    handleLogin() {
      this.isEmptyUser = !this.inputUser;
      this.isEmptyPwd = !this.inputPwd;
      this.messageError = "";

      if (this.isEmptyUser || this.isEmptyPwd) return;

      this.isLogin = true;

      window.grecaptcha
        .execute("6LcM7bkUAAAAAKppn1y0PVuMhK8VnFZEY0Ke4Tue", {
          action: "registro",
        })
        .then((recaptcha_response: string) => {
          Api("authentication")
            .post(
              {
                password: this.inputPwd,
                username: this.inputUser,
                recaptcha_response,
              },
              new Headers()
            )
            .then((res) => {
              this.isLogin = false;

              if (!res.jwt_token) {
                this.messageError = res.info.user_msg;
                return;
              }

              const account = new Account(res.jwt_token, res.user.account_type);
              this.$store.dispatch("setAccount", account);

              switch (res.user.account_type) {
                case "admin":
                  location.href = '/admin'
                  break;
                default:
                  console.log("err");
                  break;
              }
            })
            .catch((e) => {
              this.isLogin = false;
              console.log(e);
            });
        });
    },
  },
});
</script>

<style scoped>
.home-container {
  width: 390px;
}

.img-logo {
  height: 100px;
  width: 312px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("~@/assets/images/logos/logo.png");
}
</style>