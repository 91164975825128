
import { defineComponent } from "vue";
import { ESTADO_BONO } from "../../configs/constant"

export default defineComponent({
  name: "StateFilter",
  data(){
      return {
          states: {}
      }
  },
  created(){
      this.states = ESTADO_BONO;
  },  
  methods: {
    handleChange: function (e: any) {
      this.$emit("handleChange", e.target.value);
    },
  },
});
