<template>
  <div
    class="m-md-4 m-0 p-3 px-md-5 px-1 my-4 shadow-sm rounded border-light"
    style="background: white"
  >
    <span class="fs-4">Tipos de cambio - Sacoin</span>
    <TableSkeleton v-show="onLoad" />
    <table v-show="!onLoad" class="table table-hover">
      <thead>
        <tr>
          <th>Moneda</th>
          <th>Compra</th>
          <th>Venta</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Colombia</td>
          <td>${{exchangeTable['COP-Compra']}}</td>
          <td>${{exchangeTable['COP-Venta']}}</td>
        </tr>
        <tr>
          <td>México</td>
          <td>${{exchangeTable['MXN-Compra']}}</td>
          <td>${{exchangeTable['MXN-Venta']}}</td>
        </tr>
        <tr>
          <td>Argentina</td>
          <td>${{exchangeTable['ARG-Compra']}}</td>
          <td>${{exchangeTable['ARG-Venta']}}</td>
        </tr>
        <tr>
          <td>Chile</td>
          <td>${{exchangeTable['CLP-Compra']}}</td>
          <td>${{exchangeTable['CLP-Venta']}}</td>
        </tr>
        <tr>
          <td>Perú</td>
          <td>${{exchangeTable['PEN-Compra']}}</td>
          <td>${{exchangeTable['PEN-Venta']}}</td>
        </tr>
        <tr>
          <td>Bolivia</td>
          <td>${{exchangeTable['BOB-Compra']}}</td>
          <td>${{exchangeTable['BOB-Venta']}}</td>
        </tr>
        <tr>
          <td>Euro</td>
          <td>${{exchangeTable['EUR-Compra']}}</td>
          <td>${{exchangeTable['EUR-Venta']}}</td>
        </tr>
        <tr>
          <td>USD</td>
          <td>${{exchangeTable['USA-Compra']}}</td>
          <td>${{exchangeTable['USA-Venta']}}</td>
        </tr>
        <tr>
          <td>BTC</td>
          <td class="text-center" colspan="2">${{exchangeTable['BTC']}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { TableSkeleton } from "../../components/Skeleton";
export default defineComponent({
  name: "CurrencyExchangeTable",
  components: {
        TableSkeleton
  },
  data(){
    return {
      onLoad: true,
      exchangeTable: {
      }
    }
  },
  created(){
    fetch('https://www.sistemaapple.com.mx/api/getTiposCambio', {
      method: 'GET'
    }).then( res => res.json())
    .then(res => {
      this.onLoad = false;
      this.exchangeTable = res
    })
  }
});
</script>

<style scoped>
</style>