
import { defineComponent } from "vue";

// Components
import { NavBarAdmin } from "../../components/Nav";
import { CurrencyExchangeTable } from "../../components/Table";
// import { CurrencyExchangeCalculator } from "../../components/Admin";

export default defineComponent({
  name: "AdminPanel",
  components: {
    NavBarAdmin,
    CurrencyExchangeTable,
    // CurrencyExchangeCalculator
    // AwaitingRequests
  },
});
