import YearMonth from "@/instances/YearMonth";

export default class DateFilter {
    year: number
    month: number
    day: number

    /**
     * 
     * @param day 
     * @param month 0..11,
     * @param year 
     */
    constructor() {
        const date = new Date();
        date.setMonth(date.getMonth() - 1)
        this.year = date.getFullYear()
        this.month = date.getMonth()
        this.day = date.getDate()
    }

    getYear(): string {
        return this.year.toString()
    }

    getMonth(): string {
        return this.month + 1 > 10
            ? String(this.month + 1)
            : "0" + (this.month + 1)
    }

    setDate({ year, month }: YearMonth): void {
        this.year = Number(year)
        this.month == Number(month) - 1;
    }


}