
import { defineComponent } from "vue";
export default defineComponent({
  name: "Button",
  props: {
    text: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "black",
    },
    icon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dBgColor: "btn-dark" as string,
    };
  },
  created() {
    switch (this.bgColor) {
      case "black":
        this.dBgColor = "btn-dark text-white";
        break;
      case "green":
        this.dBgColor = "btn-success";
        break;
      case "yellow":
        this.dBgColor = "btn-warning";
        break;
      case "blue":
         this.dBgColor = "btn-blue text-white ";
        break;
      case "red":
        this.dBgColor = "btn-danger"
        break;
    }
  },
});
