import Config from "../configs/configs"
const credentials = localStorage.getItem('jwt');

const api2 = (resource: string) => {
    const url = `${Config.API.BASE + resource}`;
    const myHeaders = new Headers();
    //myHeaders.append("Content-Type", "application/json");
    if (credentials) {
        myHeaders.append("Authorization", "Bearer " + credentials);
    }

    return ({
        get: (id = "") =>
            fetch(`${url}${id ? `/${id}` : ''}`, {
                method: 'GET',
                headers: myHeaders,
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } {
                        if(res.status == 401)
                        throw new Error(String(res.status))
                        //
                    }
                }).catch(err => {
                    console.log(err.message)
                    if(err.message != 401)
                        return err
                    localStorage.clear();
                    location.href = '/login'
                } )
                .catch(res => {
                    console.log(res)
                    /*
                    if (res.message == 401) {
                        localStorage.clear();
                        location.href = '/login'
                    } else {
                        //location.href = '/en-mantenimiento'
                    }
                    */
                }),
        patch: (id: string, body = {}) =>
            fetch(`${url}/${id}`, {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(body)
            })
                .then(x => x.json()),
        put: (id: string, body = {}) =>
            fetch(`${url}/${id}`, {
                method: 'PUT',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(body)
            })
                .then(res => {
                    // && res.headers.get("content-type").indexOf("application/json") !== -1 
                    if (res.ok) {
                        try {
                            return res.json()
                        } catch (err) {
                            res.text().then(content => {
                                console.error(`Api.useFormData.post(${res.url}) content:`)
                                console.error(content)
                            })
                            return { success: false }
                        }

                    } else {
                        return res.text().then(content => {
                            console.error(`Api.useFormData.post(): Headers["content-type"]:"${res.headers.get("content-type")}"`)
                            console.log(content)

                            return { success: false }
                        })

                    }
                }).catch(res => {
                    console.log('catch')
                    if (res[0] == '{') {
                        const err = JSON.parse(res.message)
                        if (err.status == 401) {
                            localStorage.clear();
                            location.href = '/login'
                        } else if (err.status == 200) {
                            console.error(`Api.useFormData.post(): Headers["content-type"]:"${err.contentType}"`)
                            console.log(err.content)
                        } else {
                            console.log(err.content)
                        }

                        return { success: false }
                        //TypeError
                    }
                }),
        post: (body = {}, headers: Headers = myHeaders) =>
            fetch(`${url}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })
                .then(x => x.json()),
        delete: (id: string, body = {}) =>
            fetch(`${url}/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(body)
            })
                .then(x => x.json()),
    })
}

export default api2;