<template>
  <div class="home">
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


// Components

export default defineComponent({
  name: 'Home',
  created() {
    if(this.$store.getters.getAccountJwt)
      this.$router.push({name: 'AdminPanel'})
    else
      this.$router.push({name: 'Login'})
  },
});
</script>
