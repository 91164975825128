<template>
  <div>
    <ThSkeleton />
    <TrSkeleton />
    <TrSkeleton />
    <TrSkeleton />
    <TrSkeleton />
  </div>
</template>


<script>
import TrSkeleton from "./TrSkeleton.vue"
import ThSkeleton from "./ThSkeleton.vue"

import { defineComponent } from "vue";
export default defineComponent({
  name: "TableSkeleton",
  components: {
    TrSkeleton,
    ThSkeleton
  }
});
</script>

<style scoped>

</style>